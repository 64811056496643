<template>
  <div class="relative flex h-full overflow-auto">
    <BaseButton
      v-if="showScrollToTop"
      @press="scrollToTop()"
      type="primary"
      size="sm"
      class="absolute bottom-10 right-[2.5rem] z-50"
    >
      <ArrowUpIcon class="mr-2 inline h-3 w-3" />Back to Top</BaseButton
    >
    <BaseLoader v-if="loading" class="mx-auto mt-10" />
    <VirtualList
      v-else-if="news.length"
      ref="scroller"
      :id="`news-feed-${feed ? feed.id : folder ? folder.id : ''}`"
      class="w-full overflow-y-auto pb-16"
      @tobottom="getOlderNews"
      @unstar-news="unstarNews"
      :data-key="'link_hash'"
      :data-sources="news"
      :data-component="itemComponent"
      :extra-props="{ 'show-grouped-news-items': showGroupedItems, 'not-chain-feed': notChainFeed }"
    >
    </VirtualList>
    <div v-else class="mt-10 h-96 w-full px-10 text-center text-gray-400">
      No news found {{ feed ? "for this feed's criteria" : 'in this folder' }}.
    </div>

    <div class="absolute bottom-0 bg-gray-900" style="width: calc(100% - 10px)" v-if="loadMoreNews">
      <NewsFeedTemplateCard />
    </div>
  </div>
</template>
<script setup>
import { DateTime } from 'luxon';
import VirtualList from 'vue3-virtual-scroll-list';
import VirtualScrollItemSimpleNews from '@/components/virtual_scroll_item/VirtualScrollItemSimpleNews.vue';
import NewsFeedTemplateCard from '@/components/load_templates/NewsFeedTemplateCard.vue';
import { ref, onMounted, onBeforeUnmount, computed, watch, inject } from 'vue';
import { flashMessage } from '@/composeables/helpers';
import useEmitter from '@/composeables/emitter';
import useHttp from '@/composeables/http';
import { chain, get, find, includes, orderBy, compact, uniqBy } from 'lodash';
import { ArrowUpIcon } from '@heroicons/vue/20/solid';
import VirtualScrollItemNews from '../virtual_scroll_item/VirtualScrollItemNews.vue';
import { groupNews } from '@/composeables/news';
import { useStore } from 'vuex';

const props = defineProps({
  feed: { type: Object, default: () => {} },
  search: { type: String, default: '' },
  leaveOffTime: { type: String, default: '' },
  simpleNewsItem: { type: Boolean, default: false },
  folder: { type: Object, default: () => {} }
});

const $store = useStore();
const $eventHub = useEmitter();
const $http = useHttp();
const $emit = defineEmits(['unread-items']);
const width = inject('width');

const news = ref([]);
const loading = ref(true);
const latestTimestamp = ref(null);
const pollingInterval = ref(null);
const showScrollToTop = ref(false);
const loadMoreNews = ref(false);
const teamNewsItems = ref([]);
const scroller = ref(null);
const requestInProgress = ref(false);
const groupSimilarNews = ref(false);
const showGroupedItems = ref([]);
const lastNewsItem = ref({});
onMounted(() => {
  if (props.feed || props.folder) {
    getNews();
  }
  pollingInterval.value = setInterval(() => {
    getNews(true);
  }, 20000);
  $eventHub.$on('excludeSource', payload => {
    blockSource(payload);
  });
  $eventHub.$on('newsItemUpdated', async newsItem => {
    matchUpTeamNewsItems();
  });
  $eventHub.$on('toggle:show-grouped-items', id => {
    if (includes(showGroupedItems.value, id)) {
      showGroupedItems.value = showGroupedItems.value.filter(item => item !== id);
    } else {
      showGroupedItems.value.push(id);
    }
  });
});
watch(
  () => props.folder,
  (newFolder, oldFolder) => {
    getNews();
  }
);
onBeforeUnmount(() => {
  clearInterval(pollingInterval.value);
  $eventHub.$off('excludeSource');
  $eventHub.$off('newsItemUpdated');
});

const itemComponent = computed(() => {
  return props.simpleNewsItem ? VirtualScrollItemSimpleNews : VirtualScrollItemNews;
});
const notChainFeed = computed(() => props.feed?.name != 'On-Chain Signal');
watch(
  () => props.leaveOffTime,
  () => {
    findItemClosestToCheckpoint();
  }
);

watch(
  news,
  () => {
    if (props.leaveOffTime) {
      let unreadItems = news.value.filter(n => new Date(n.timestamp) > new Date(props.leaveOffTime)).length;
      if (unreadItems > 0) {
        $emit('unread-items', unreadItems);
      }
    }
  },
  { deep: true, immediate: true }
);

function getNews(is_latest = false) {
  if (!requestInProgress.value) {
    if (latestTimestamp.value) {
      var startDatetime = DateTime.fromISO(latestTimestamp.value).plus({ seconds: 1 }).toISO();
    }
    var params = {
      news_feed_id: props.feed?.id,
      news_folder_id: props.folder?.id,
      start_datetime: startDatetime,
      search: props.search,
      group_similar_news: props.feed?.group_similar_news,
      limit: 25
    };
    if (props.feed?.name == 'Firehose') {
      params = { ...params, want_firehose: true };
    }
    requestInProgress.value = true;
    $http
      .get('/data/news', {
        params: params
      })
      .then(async response => {
        let newsData = response.data.data;
        if (!is_latest && newsData.length > 0) lastNewsItem.value = response.data?.last_news_item;
        var newNews = props.feed?.group_similar_news ? groupNews(newsData, news.value) : newsData;
        if (newNews.length > 0) {
          newNews.reverse().forEach(item => {
            news.value.unshift(item);
          });
          await matchUpTeamNewsItems();
          if (props.feed?.checkpoint) {
            findItemClosestToCheckpoint();
          }
        }
        if (news.value && news.value[0]) {
          latestTimestamp.value = news.value[0].timestamp;
        }
      })
      .finally(() => {
        loading.value = false;
        requestInProgress.value = false;
      });
  }
}
function scrollToTop() {
  showScrollToTop.value = false;
  scroller.value.scrollToOffset(0);
}
function getOlderNews() {
  if (!requestInProgress.value) {
    showScrollToTop.value = true;
    loadMoreNews.value = true;
    requestInProgress.value = true;
    let date_time_param = props.feed?.group_similar_news
      ? lastNewsItem.value.timestamp
      : news.value[news.value.length - 1].timestamp;
    var params = {
      news_feed_id: props.feed?.id,
      news_folder_id: props.folder?.id,
      end_datetime: date_time_param,
      search: props.search,
      group_similar_news: props.feed?.group_similar_news,
      limit: 50
    };
    if (props.feed?.name == 'Firehose') {
      params = { ...params, want_firehose: true };
    }
    $http
      .get('/data/news', {
        params: params
      })
      .then(async response => {
        let newsData = response.data.data;
        if (newsData.length > 0) lastNewsItem.value = response.data?.last_news_item;
        var newNews = props.feed?.group_similar_news ? groupNews(newsData, news.value) : newsData;
        if (newNews.length > 0) {
          newNews.forEach(item => {
            news.value.push(item);
          });
          await matchUpTeamNewsItems();
        }
        loadMoreNews.value = false;
      })
      .finally(() => {
        requestInProgress.value = false;
      });
  }
}
function findItemClosestToCheckpoint() {
  if (props.leaveOffTime == new Date(0).toISOString()) {
    var item = news.value.find(n => n.checkpoint);
    if (item) {
      item.checkpoint = null;
    }
  } else {
    // This assumes news is sorted from newest to oldest (find first item less than checkpoint) to mark blue line
    var checkpointItem = news.value.find(n => n.timestamp < props.leaveOffTime);
    if (checkpointItem) {
      checkpointItem.checkpoint = props.leaveOffTime;
    }
  }
}
async function matchUpTeamNewsItems() {
  const { data } = await $http.get('/data/team_news_items');
  teamNewsItems.value = data;
  news.value.forEach(item => {
    var match = teamNewsItems.value.find(i => i.news_item_id == item.id);
    item.is_starred_by_my_team = match?.is_starred_by_my_team;
    item.starred_by_team_member = match?.starred_by_team_member;
    if (item.similarNews) {
      item.grouped.map(news => {
        var match = teamNewsItems.value.find(i => i.news_item_id == news.id);
        news.is_starred_by_my_team = match?.is_starred_by_my_team;
        news.starred_by_team_member = match?.starred_by_team_member;
      });
    }
  });
}
async function blockSource(source) {
  news.value.forEach(item => {
    if (item.sources.some(s => s.id == source.id)) {
      item['hide'] = true;
    }
  });
  $http
    .post('/blocked_news_feed_entities', {
      news_feed_id: props.feed?.id,
      entity_type: 'Source',
      entity_id: source.id
    })
    .then(() => {
      $eventHub.$emit('addBlockSource', source);
      flashMessage({ type: 'success', message: `Blocked ${source.name} for this feed.` });
    });
}

function unstarNews(id) {
  if (props.feed?.is_starred_by_my_team) {
    news.value.splice(
      news.value.findIndex(v => v.id === id),
      1
    );
  }
}
</script>
